body {
  margin: 0;
  color: #353535;
  background-color: rgb(250, 250, 250);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Lato, sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tox.tox-tinymce-inline .tox-editor-header {
  border-radius: 0 !important;
}
.mce-content-body {
  outline: 0px solid transparent;
  height: 100%;
  overflow: hidden;
  font-weight: 300;
}
.mce-content-body h1 {
  font-size: 2em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body h2 {
  font-size: 1.5em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body h3 {
  font-size: 1.17em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body h4 {
  font-size: 1em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body h5 {
  font-size: 0.83em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body h6 {
  font-size: 0.67em;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.mce-content-body pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0;
}
.mce-content-body ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.mce-content-body ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: #a3a3a3 !important;
}
@media print {
  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: transparent !important;
  }
}
