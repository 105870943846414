input,
textarea {
  font: inherit;
}

ol,
ul {
  list-style: none;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: inherit;
}

input {
  border: 0;
}

html {
  box-sizing: border-box;
}

.toggle-button {
  position: relative;

  width: 26px;
  height: 15px;
  margin: 0;

  vertical-align: top;

  background: #757575;
  border: 1px solid #bbc1e1;
  border-radius: 30px;
  outline: none;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: all 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button::after {
  content: '';

  position: absolute;
  /*   left: 0.5px; */
  /*   top: 1px; */

  width: 14px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  border-color: #757575;

  transform: translateX(0);

  transition: all 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button:checked::after {
  transform: translateX(calc(100% - 4px));
  background-color: #fff;
  border-color: #f47457;
}

.toggle-button:checked {
  background-color: #f47457;
}

.cropper-point {
  visibility: hidden;
}

.cropper-view-box {
  outline: 3px solid #f47457;
}

.cropper-modal {
  background-color: #9c9c9c;
  opacity: 0.5;
}

.cropper-line {
  background-color: #f47457;
}

.cropper-move {
  cursor: grab;
}
